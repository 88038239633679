export default {
  external: {
    children: "And I'm external",
    to: "https://ample.co"
  },
  internal: {
    children: "I'm an internal link",
    to: "/?path=/docs/components-link--props#internal-link"
  },
  props: {
    children: "Lorem dolor",
    to: "/?path=/docs/components-link--props#internal-link"
  }
}
