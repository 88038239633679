import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import Button from "@components/button"

const ContentBlock = ({
  body,
  bodyNode = {},
  buttonTheme,
  children,
  className,
  ctas = [],
  subhead,
  subtitle,
  title,
  withoutBody
}) => {
  const ButtonCtas = () => {
    return ctas
      ? ctas.map((cta, index) => {
          return (
            <Button key={index} href={cta.href} theme={cta.theme || buttonTheme}>
              {cta.label}
            </Button>
          )
        })
      : []
  }

  // ---------------------------------------------------------

  const bodyContent =
    bodyNode && bodyNode.childMarkdownRemark ? bodyNode.childMarkdownRemark.html : body

  return (
    <div className={classNames({ [className]: className })}>
      {children !== undefined && children}

      {title && <h2>{title}</h2>}
      {subhead && <h2 className="green">{subhead}</h2>}
      {subtitle && <h3 className="green">{subtitle}</h3>}

      {!withoutBody && bodyContent && (
        <div
          dangerouslySetInnerHTML={{
            __html: bodyContent
          }}
        />
      )}

      {ctas && (
        <div className="ctas">
          <ButtonCtas />
        </div>
      )}
    </div>
  )
}

ContentBlock.propTypes = {
  /**
   * HTML string to be rendered to the page.
   */
  body: PropTypes.string,
  bodyNode: PropTypes.object,
  buttonTheme: PropTypes.string,
  children: PropTypes.node,
  ctas: PropTypes.array,
  subhead: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  /**
   * Specifies if body should be rendered to the page
   */
  withoutBody: PropTypes.bool
}

ContentBlock.defaultProps = {}

export default ContentBlock
