import React from "react"
import PropTypes from "prop-types"
import * as styles from "./styles.module.scss"
import SVG from "@components/svg"
import classNames from "classnames"

// -------------------------------------------------------- | component

const TriangleBackground = ({ shouldDisplay, noHeaderFooter }) => {
  const classes = classNames(styles.triangle, {
    [styles.no_header_footer]: noHeaderFooter
  })

  if (shouldDisplay) {
    return (
      <div className={classes}>
        <SVG name="triangle-background" />
      </div>
    )
  }

  return null
}

TriangleBackground.propTypes = {
  noHeaderFooter: PropTypes.bool,
  shouldDisplay: PropTypes.bool
}

TriangleBackground.defaultProps = {
  noHeaderFooter: false,
  shouldDisplay: true
}

export default TriangleBackground
