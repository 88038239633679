import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

// ---------------------------------------------------------

import {
  grid,
  gutter_15,
  layout_is_half,
  layout_is_third,
  layout_is_four
} from "./styles.module.scss"

// ---------------------------------------------------------

import Components from "@layout/components"

// ---------------------------------------------------------

const gutterOptions = {
  0: 0,
  15: gutter_15
}

const layoutOptions = {
  2: layout_is_half,
  3: layout_is_third,
  4: layout_is_four,
  half: layout_is_half
}

// ---------------------------------------------------------

const Grid = ({
  children,
  className,
  components,
  gutter,
  hasChildren,
  items = [],
  layout = []
}) => {
  if (!layout && hasChildren && items) {
    layout = items
  } else if (!layout && hasChildren && children) {
    layout = children.length
  } else if (!layout && components) {
    layout = components.length
  }

  const classes = classNames(grid, {
    [className]: className,
    [gutterOptions[gutter]]: gutterOptions[gutter],
    [layoutOptions[layout]]: layoutOptions[layout]
  })

  // ---------------------------------------------------------

  return (
    <div className={classes}>
      {hasChildren
        ? children
        : components &&
          components.map((component, index) => {
            return <Components key={index} {...component} />
          })}
    </div>
  )
}

Grid.propTypes = {
  /**
   * Contains components that get passed on to the <Grid />.
   */
  children: PropTypes.node,

  /**
   * An array of components that get passed on to <Component />
   */
  components: PropTypes.arrayOf(PropTypes.object),

  /**
   * Specifies the size of the grid's gutter.
   */
  gutter: PropTypes.oneOf(Object.keys(gutterOptions)),

  /**
   * Specifies if the container has children instead of dynamically rendering components.
   */
  hasChildren: PropTypes.bool,

  items: PropTypes.oneOf(Object.keys(layoutOptions)),

  /**
   * Specifies the width of each child in the grid on larger screens
   */
  layout: PropTypes.oneOf(Object.keys(layoutOptions))
}

Grid.defaultProps = {
  gutter: "15"
}

export default Grid
