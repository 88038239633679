import Button from "@components/button"
import Card from "@components/card"
// import Container from "@layout/container"
import ContentBlock from "@components/content-block"
import Grid from "@layout/grid"
import Image from "@components/image"

export default {
  button: Button,
  card: Card,
  // container: Container,
  content_block: ContentBlock,
  grid: Grid,
  image: Image
}
