export default {
  default: {
    activeClassName: "active",
    activeStyle: {},
    children: "Hello World",
    className: "",
    onClick: null,
    to: "/"
  }
}
