// extracted by mini-css-extract-plugin
export var container = "container-38p";
export var container_background_image = "container_background_image-2Iq";
export var content = "content-s_H";
export var container_header = "container_header-3Yx";
export var align_content_center = "align_content_center-2ct";
export var has_green_border = "has_green_border-1wN";
export var notch_is_top_left_footer = "notch_is_top_left_footer-2lh";
export var notch_is_top_left_half = "notch_is_top_left_half-22U";
export var notch_is_top_right_fourth = "notch_is_top_right_fourth-3ks";
export var padding_footer = "padding_footer-39o";
export var padding_medium = "padding_medium-3K3";
export var text_alignment_center = "text_alignment_center-2Bx";
export var text_alignment_left = "text_alignment_left-R09";
export var text_alignment_right = "text_alignment_right-hrD";
export var theme_background_has_gray_triangle = "theme_background_has_gray_triangle-3LZ";
export var theme_background_is_black = "theme_background_is_black-3hY";
export var theme_background_is_gray = "theme_background_is_gray-8hY";
export var theme_background_is_green = "theme_background_is_green-3MD";
export var theme_background_is_white = "theme_background_is_white-1MP";
export var width_full = "width_full-117";
export var width_fixed = "width_fixed-1if";