import React, { useState } from "react"
import { useMediaQuery } from "react-responsive"
import OutsideClickHandler from "react-outside-click-handler"
import PropTypes from "prop-types"
import { Dropdown } from "./dropdown"
import Link from "@src/components/link"
import Button from "@src/components/button"

import * as styles from "./styles.module.scss"

const Navigation = ({ links = [], onClick }) => {
  const [openDropdown, setOpenDropdown] = useState("")
  const classes = styles.navigation

  const handleQueryChange = (match) => {
    if (!match) {
      setOpenDropdown("")
    }
  }

  const isMobileNavigation = useMediaQuery(
    { query: "(max-width: 65em)" },
    undefined,
    handleQueryChange
  )

  const handleDropdownClick = (label) => {
    label === openDropdown ? setOpenDropdown("") : setOpenDropdown(label)
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setOpenDropdown("") || isMobileNavigation}>
      <ul className={classes}>
        {links.map((item, index) => {
          if (item.children && item.children.length > 0) {
            return (
              <li key={index}>
                <Dropdown
                  handleDropdownClick={handleDropdownClick}
                  items={item.children}
                  key={index}
                  label={item.title}
                  onClick={onClick}
                  openDropdown={openDropdown}
                />
              </li>
            )
          } else {
            return (
              <li key={index}>
                <Link
                  onClick={onClick}
                  title={item.title}
                  to={item.href}
                  className={styles.nav_link}
                >
                  {item.title}
                </Link>
              </li>
            )
          }
        })}
        <div className={styles.cta}>
          <Button to="/contact">Contact</Button>
        </div>
      </ul>
    </OutsideClickHandler>
  )
}

Navigation.propTypes = {
  /**
   * An array of link objects used to build the list
   */
  links: PropTypes.array.isRequired,
  /**
   * A onClick function passed to all navigation links
   */
  onClick: PropTypes.func
}

export default Navigation
