// extracted by mini-css-extract-plugin
export var form = "form-1xC";
export var form_field_group = "form_field_group-21h";
export var form_field = "form_field-2Il";
export var solo_field = "solo_field-1iY";
export var form_label = "form_label-Dk-";
export var radio_buttons = "radio_buttons-1_a";
export var radio_button = "radio_button-PpQ";
export var circle = "circle-2BQ";
export var radio = "radio-36S";
export var width_half = "width_half-3AY";
export var width_quarter = "width_quarter-lii";