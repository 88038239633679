import React from "react"
import PropTypes from "prop-types"

import { Helmet } from "react-helmet"

const SEO = ({ metaTags }) => {
  const generateTitleTag = ({ content }, key) => {
    return <title key={key}>{content}</title>
  }

  const generateMetaTag = ({ attributes }, key) => {
    return <meta {...attributes} key={key} />
  }

  return (
    <Helmet>
      {metaTags.map((tag, index) => {
        if (tag.tagName === "title") {
          return generateTitleTag(tag, index)
        }

        if (tag.tagName === "meta") {
          return generateMetaTag(tag, index)
        }

        return null
      })}
      <meta name="google-site-verification" content="yB52Vhn1F5xJ1_XEfMg0ZkPOxxn5MH5LQGzRjW2M0dw" />
    </Helmet>
  )
}

SEO.propTypes = {
  metaTags: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.shape({
        content: PropTypes.string,
        name: PropTypes.string,
        property: PropTypes.string
      }),
      content: PropTypes.string,
      tagName: PropTypes.oneOf(["meta", "title"]).isRequired
    })
  )
}

SEO.defaultProps = {
  metaTags: []
}

export default SEO
