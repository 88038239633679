import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import SVG from "@components/svg"
import Link from "@components/link"
import * as styles from "./styles.module.scss"

// ---------------------------------------------------------

export const themeOptions = {
  arrow: styles.theme_arrow,
  "arrow-without-padding": styles.theme_arrow_without_padding,
  default: styles.theme_default,
  outline: styles.theme_outline
}

// ---------------------------------------------------------

const Button = ({ children, className, disabled, id, onClick, theme, to, type }) => {
  const hasArrow =
    theme.toLowerCase() === "arrow" ||
    theme.toLowerCase().replace(/\s+/g, "-") === "arrow-without-padding"

  const classes = classNames(styles.button, {
    [className]: className,
    [themeOptions[theme.toLowerCase().replace(/\s+/g, "-")]]:
      themeOptions[theme.toLowerCase().replace(/\s+/g, "-")]
  })

  const buttonContents = (
    <>
      {children}
      {hasArrow && <SVG name="caret-right" />}
    </>
  )

  let buttonComponent

  if (to && to !== undefined) {
    buttonComponent = (
      <Link to={to} className={classes} onClick={onClick}>
        {buttonContents}
      </Link>
    )
  } else {
    buttonComponent = (
      <button
        className={classes}
        onClick={
          onClick &&
          ((e) => {
            if (disabled) {
              e.preventDefault()
            } else {
              onClick(e)
            }
          })
        }
        type={type}
        disabled={disabled}
        id={id}
      >
        {buttonContents}
      </button>
    )
  }

  return buttonComponent
}

Button.propTypes = {
  /**
   * Specifies what is rendered inside the button.
   */
  children: PropTypes.string.isRequired,
  /**
   * Specifies a click function and renders a `<button>` element.
   */
  onClick: PropTypes.func,
  /**
   * Specifies the theme.
   */
  theme: PropTypes.string,
  /**
   * Specifies the href attribute
   */
  to: PropTypes.string,
  /**
   * Specifies the type attribute and renders a `<button>` element.
   */
  type: PropTypes.oneOf(["submit", "button"])
}

Button.defaultProps = {
  theme: "default"
}

export default Button
