import React, { useState, useEffect } from "react"
import { useMediaQuery } from "react-responsive"
import PropTypes from "prop-types"
import classNames from "classnames"

import SVG from "@components/svg"
import Link from "@components/link"
import Navigation from "./navigation"

import * as styles from "./styles.module.scss"

const Header = (data) => {
  const [menuIsOpen, setMenu] = useState(false)

  const handleMediaQueryChange = (match) => {
    if (!match) {
      lockScroll(false)
      setMenu(false)
    }
  }

  const isMobileNavigation = useMediaQuery({ maxWidth: 1080 }, null, handleMediaQueryChange)

  const lockScroll = (menuIsOpen) => {
    overflowHidden(menuIsOpen)
  }

  const mobileMenuClick = () => {
    if (isMobileNavigation) {
      lockScroll(!menuIsOpen)
      setMenu(!menuIsOpen)
    }
  }

  const overflowHidden = (menuIsOpen) => {
    if (menuIsOpen) {
      document.documentElement.style.overflow = "hidden"
    } else {
      document.documentElement.style.removeProperty("overflow")
    }
  }

  useEffect((menuIsOpen) => {
    overflowHidden(menuIsOpen)
  }, [])

  // ------------------------------------------------------ | Classes

  const classes = classNames(styles.header, {
    [styles.navigation_is_showing]: menuIsOpen
  })

  return (
    <header className={classes}>
      <nav>
        <div>
          <div className={styles.logo_container}>
            <Link to="/" aria-label="Mike Albert Truck and Van Equipment">
              <SVG name="logo" />
            </Link>
          </div>
          <button className={styles.menu_button} onClick={mobileMenuClick}>
            <span>Menu</span>
            {!menuIsOpen ? <SVG name="bars" /> : <SVG name="times" />}
          </button>
          <Navigation links={data.links} onClick={mobileMenuClick} />
        </div>
      </nav>
    </header>
  )
}

Header.propTypes = {
  /**
   * An array of main navigation links.
   * This prop is passed to the Navigation component
   */
  links: PropTypes.array.isRequired
}

export default Header
