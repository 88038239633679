import React, { Fragment } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { useMediaQuery } from "react-responsive"

// --------------------------------------------------------

import {
  container_background_image,
  container_header,
  container,
  content,
  has_green_border,
  notch_is_top_left_footer,
  notch_is_top_left_half,
  notch_is_top_right_fourth,
  padding_footer,
  padding_medium,
  text_alignment_center,
  text_alignment_left,
  text_alignment_right,
  theme_background_has_gray_triangle,
  theme_background_is_black,
  theme_background_is_gray,
  theme_background_is_green,
  theme_background_is_white,
  width_fixed,
  width_full
} from "./styles.module.scss"

import SVG from "@components/svg"
import Image from "@components/image"
import Button from "@components/button"
import Components from "@layout/components"

// --------------------------------------------------------

const elementOptions = {
  div: "div",
  footer: "footer",
  section: "section"
}

const notchOptions = {
  none: null,
  "notch-is-top-left-footer": notch_is_top_left_footer,
  "notch-is-top-left-half": notch_is_top_left_half,
  "notch-is-top-right-fourth": notch_is_top_right_fourth
}

const paddingOptions = {
  footer: padding_footer,
  medium: padding_medium,
  none: null
}

const rowPlacementOptions = {
  first: 1,
  second: 2,
  third: 3
}

const textAlignmentOptions = {
  center: text_alignment_center,
  left: text_alignment_left,
  right: text_alignment_right
}

const themeOptions = {
  "background-has-gray-triangle": theme_background_has_gray_triangle,
  "background-is-black": theme_background_is_black,
  "background-is-gray": theme_background_is_gray,
  "background-is-green": theme_background_is_green,
  "background-is-white": theme_background_is_white,
  none: null
}

const widthOptions = {
  fixed: width_fixed,
  full: width_full
}

// --------------------------------------------------------
const Container = ({
  backgroundImage,
  backgroundImageClassName,
  children,
  className,
  components,
  contentClassName,
  element: Element,
  greenBorder,
  hasChildren,
  header,
  notch,
  padding,
  rowPlacement,
  textAlignment,
  theme,
  width
}) => {
  // ---------------------------------------------------------

  const classes = classNames(container, {
    [className]: className,
    [themeOptions[theme]]: themeOptions[theme],
    [has_green_border]: greenBorder
  })

  // ---------------------------------------------------------

  const mediaSmall = useMediaQuery({ minWidth: 720 }, null)

  // ---------------------------------------------------------

  const Component = (component) =>
    component.component === "grid" ? (
      <Components {...component} />
    ) : (
      <div>
        <Components {...component} />
      </div>
    )

  // ---------------------------------------------------------

  return (
    <Element className={classes}>
      {backgroundImage && (
        <Image
          className={classNames(container_background_image, {
            [backgroundImageClassName]: backgroundImageClassName
          })}
          src={backgroundImage}
        />
      )}

      {mediaSmall && theme === "background-has-gray-triangle" && <SVG name="triangle-background" />}

      <div
        className={classNames(content, {
          [contentClassName]: contentClassName,
          [notchOptions[notch]]: notchOptions[notch],
          [paddingOptions[padding]]: paddingOptions[padding],
          [textAlignmentOptions[textAlignment]]: textAlignmentOptions[textAlignment],
          [widthOptions[width]]: widthOptions[width]
        })}
      >
        {header && (
          <header className={container_header}>
            {header.heading && <h2>{header.heading}</h2>}
            {header.button && (
              <Button theme="arrow" {...header.button} to={header.button.url}>
                {header.button.label}
              </Button>
            )}
          </header>
        )}

        {hasChildren && components === undefined
          ? children
          : components &&
            components.map((component, index) => (
              <Fragment key={index}>
                {rowPlacement === "first" && Component(component)}
                {rowPlacement === "second" && Component(component)}
                {rowPlacement === "third" && Component(component)}
              </Fragment>
            ))}
      </div>
    </Element>
  )
}

Container.propTypes = {
  /**
   * Specifies the image src and alt text for the background image
   */
  backgroundImage: PropTypes.object,
  backgroundImageClassName: PropTypes.string,
  /**
   * Contains components that get passed on to the <Container />
   */
  children: PropTypes.node,

  /**
   * An array of components that get passed on to <Component />
   */
  components: PropTypes.arrayOf(PropTypes.object),
  contentClassName: PropTypes.string,

  /**
   * Specifies the element to use and the wrapper
   */
  element: PropTypes.oneOf(Object.keys(elementOptions)),

  /**
   * Specifies if the container has a green top border
   */
  greenBorder: PropTypes.bool,

  /**
   * Specifies if the container has children instead of dynamically rendering components.
   */
  hasChildren: PropTypes.bool,

  /**
   * Specifies the content of the container header
   */
  header: PropTypes.shape({
    button: PropTypes.object,
    heading: PropTypes.string
  }),

  /**
   * Specifies the placement and size of the notch
   */
  notch: PropTypes.oneOf(Object.keys(notchOptions)),

  /**
   * Specifies the top and bottom padding of the container
   */
  padding: PropTypes.oneOf(Object.keys(paddingOptions)),

  /**
   * Specifies which row the component should be placed in
   */
  rowPlacement: PropTypes.oneOf(Object.keys(rowPlacementOptions)),

  /**
   * Specifies the alignment of the container's text
   */
  textAlignment: PropTypes.oneOf(Object.keys(textAlignmentOptions)),

  /**
   * Specifies the theme of the container
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the width of container
   */
  width: PropTypes.oneOf(Object.keys(widthOptions))
}

Container.defaultProps = {
  element: "section",
  padding: "medium",
  rowPlacement: "first",
  width: "full"
}

export default Container
