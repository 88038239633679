import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import * as styles from "./styles.module.scss"

import Button from "@components/button"
import Image from "@components/image"
import Link from "@components/link"
import SVG from "@components/svg"

// ---------------------------------------------------------

const themeOptions = {
  "background-image": styles.theme_background_image,
  media: styles.theme_media,
  "media-image-size-constant": styles.theme_media_image_size_constant,
  testimonials: styles.testimonials
}

// ---------------------------------------------------------

const Card = ({ body, buttonLabel, heading, image, theme, url, greenBorder, children }) => {
  // ------------------------------------------------------

  const classes = classNames(styles.card, {
    [themeOptions[theme]]: themeOptions[theme],
    [styles.green_border]: greenBorder
  })
  return (
    <div className={classes}>
      <Link to={url}>
        {image && <Image className={styles.card_image} src={image} />}
        {image && theme === "background-image" && <div className={styles.card_image_overlay} />}

        <div className={styles.card_content}>
          {heading && (
            <h3>
              {heading}
              {theme === "media" && <SVG name="caret-right" />}
            </h3>
          )}
          {body && <p dangerouslySetInnerHTML={{ __html: body }} />}
          {children}

          {buttonLabel && theme === "background-image" && (
            <div>
              <Button size="small" theme="arrow" type="button">
                {buttonLabel}
              </Button>
            </div>
          )}
        </div>
      </Link>
    </div>
  )
}

Card.propTypes = {
  /**
   * Specifies the content
   * that is displayed under the heading
   */
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),

  /**
   * Specifies the label of the button on the background-image theme
   */
  buttonLabel: PropTypes.string,

  /**
   * children displayed directly in the card body
   */
  children: PropTypes.node,

  /**
   * Specifies if the card as a green top border
   */
  greenBorder: PropTypes.bool,

  /**
   * Specifies the heading
   */
  heading: PropTypes.string,

  /**
   * Specifies the image with alt text
   */
  image: PropTypes.object,

  /**
   * Specifies the card theme
   */
  theme: PropTypes.oneOf(Object.keys(themeOptions)),

  /**
   * Specifies the url for the card
   */
  url: PropTypes.string
}

Card.defaultProps = {}

export default Card
