const children = "Call to action"

const exampleFunction = () => {
  alert("Button Clicked")
}

export default {
  arrow: {
    theme: "arrow"
  },
  default: {
    children: children,
    to: "/"
  },
  externalLink: {
    children: children,
    to: "https://ample.co"
  },
  outline: {
    theme: "outline",
    to: "/"
  },
  props: {
    children: children,
    theme: "default",
    to: "/",
    type: "button"
  },
  useAnchorLink: {
    children: children,
    to: ""
  },
  useButtonElement: {
    children: children,
    onClick: exampleFunction,
    type: "button"
  }
}
