import React from "react"

import Component from "./component"
import fixtures from "./fixtures"
import svgOptions from "./svgs"

const SVG = (props) => <Component {...props} />

export default SVG

export { Component as component, fixtures, svgOptions }
