export default {
  brand_logo_ad_vehicles: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/4f951e0f01c67292695dd36975c0f0a9/c9669/logo-brand-advertising-vehicles%403x.png",
              srcSet:
                "/storybook/4f951e0f01c67292695dd36975c0f0a9/8e8c5/logo-brand-advertising-vehicles%403x.png 180w,\n/storybook/4f951e0f01c67292695dd36975c0f0a9/35ed9/logo-brand-advertising-vehicles%403x.png 360w,\n/storybook/4f951e0f01c67292695dd36975c0f0a9/c9669/logo-brand-advertising-vehicles%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/4f951e0f01c67292695dd36975c0f0a9/c45c5/logo-brand-advertising-vehicles%403x.webp 180w,\n/storybook/4f951e0f01c67292695dd36975c0f0a9/79124/logo-brand-advertising-vehicles%403x.webp 360w,\n/storybook/4f951e0f01c67292695dd36975c0f0a9/4e0d9/logo-brand-advertising-vehicles%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "81affc51-dbb8-5135-8f3a-0b3d0cdc49bc"
      }
    }
  },
  brand_logo_adrian_steel: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/29cd7993dc4f5b55773ff4e91715ffaf/c9669/logo-brand-adrian-steel%403x.png",
              srcSet:
                "/storybook/29cd7993dc4f5b55773ff4e91715ffaf/8e8c5/logo-brand-adrian-steel%403x.png 180w,\n/storybook/29cd7993dc4f5b55773ff4e91715ffaf/35ed9/logo-brand-adrian-steel%403x.png 360w,\n/storybook/29cd7993dc4f5b55773ff4e91715ffaf/c9669/logo-brand-adrian-steel%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/29cd7993dc4f5b55773ff4e91715ffaf/c45c5/logo-brand-adrian-steel%403x.webp 180w,\n/storybook/29cd7993dc4f5b55773ff4e91715ffaf/79124/logo-brand-adrian-steel%403x.webp 360w,\n/storybook/29cd7993dc4f5b55773ff4e91715ffaf/4e0d9/logo-brand-adrian-steel%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "da7a921e-0dc3-58c2-ba8c-47ae2876698d"
      }
    }
  },
  brand_logo_american_van: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/f3e917d141e6b359d386a77995fae55b/c9669/logo-brand-american-van%403x.png",
              srcSet:
                "/storybook/f3e917d141e6b359d386a77995fae55b/8e8c5/logo-brand-american-van%403x.png 180w,\n/storybook/f3e917d141e6b359d386a77995fae55b/35ed9/logo-brand-american-van%403x.png 360w,\n/storybook/f3e917d141e6b359d386a77995fae55b/c9669/logo-brand-american-van%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/f3e917d141e6b359d386a77995fae55b/c45c5/logo-brand-american-van%403x.webp 180w,\n/storybook/f3e917d141e6b359d386a77995fae55b/79124/logo-brand-american-van%403x.webp 360w,\n/storybook/f3e917d141e6b359d386a77995fae55b/4e0d9/logo-brand-american-van%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "d9b7642f-98f9-5fd9-a82e-c59f053ea8cf"
      }
    }
  },
  brand_logo_are: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/c8ed765df9e25bd9cd9837cbfc017afa/c9669/logo-brand-are%403x.png",
              srcSet:
                "/storybook/c8ed765df9e25bd9cd9837cbfc017afa/8e8c5/logo-brand-are%403x.png 180w,\n/storybook/c8ed765df9e25bd9cd9837cbfc017afa/35ed9/logo-brand-are%403x.png 360w,\n/storybook/c8ed765df9e25bd9cd9837cbfc017afa/c9669/logo-brand-are%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/c8ed765df9e25bd9cd9837cbfc017afa/c45c5/logo-brand-are%403x.webp 180w,\n/storybook/c8ed765df9e25bd9cd9837cbfc017afa/79124/logo-brand-are%403x.webp 360w,\n/storybook/c8ed765df9e25bd9cd9837cbfc017afa/4e0d9/logo-brand-are%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "5bc072c1-3528-5e7e-96bd-2ab9818b7107"
      }
    }
  },
  brand_logo_cargo_glide: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/1fea3f08e16da9b1de79374fdca4b6d2/c9669/logo-brand-cargoglide%403x.png",
              srcSet:
                "/storybook/1fea3f08e16da9b1de79374fdca4b6d2/8e8c5/logo-brand-cargoglide%403x.png 180w,\n/storybook/1fea3f08e16da9b1de79374fdca4b6d2/35ed9/logo-brand-cargoglide%403x.png 360w,\n/storybook/1fea3f08e16da9b1de79374fdca4b6d2/c9669/logo-brand-cargoglide%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/1fea3f08e16da9b1de79374fdca4b6d2/c45c5/logo-brand-cargoglide%403x.webp 180w,\n/storybook/1fea3f08e16da9b1de79374fdca4b6d2/79124/logo-brand-cargoglide%403x.webp 360w,\n/storybook/1fea3f08e16da9b1de79374fdca4b6d2/4e0d9/logo-brand-cargoglide%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "93424bb9-7a68-5a5d-ac79-09958e6a069e"
      }
    }
  },
  brand_logo_decked: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/d31e7af0fc2211c789e32eb4b3424f1a/c9669/logo-brand-decked%403x.png",
              srcSet:
                "/storybook/d31e7af0fc2211c789e32eb4b3424f1a/8e8c5/logo-brand-decked%403x.png 180w,\n/storybook/d31e7af0fc2211c789e32eb4b3424f1a/35ed9/logo-brand-decked%403x.png 360w,\n/storybook/d31e7af0fc2211c789e32eb4b3424f1a/c9669/logo-brand-decked%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/d31e7af0fc2211c789e32eb4b3424f1a/c45c5/logo-brand-decked%403x.webp 180w,\n/storybook/d31e7af0fc2211c789e32eb4b3424f1a/79124/logo-brand-decked%403x.webp 360w,\n/storybook/d31e7af0fc2211c789e32eb4b3424f1a/4e0d9/logo-brand-decked%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "b58663c3-0d42-587c-ad5e-a4a6b44d5bbe"
      }
    }
  },
  brand_logo_leer: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/a35ecd5cb43cbcc0843467f82e8839d7/c9669/logo-brand-leer%403x.png",
              srcSet:
                "/storybook/a35ecd5cb43cbcc0843467f82e8839d7/8e8c5/logo-brand-leer%403x.png 180w,\n/storybook/a35ecd5cb43cbcc0843467f82e8839d7/35ed9/logo-brand-leer%403x.png 360w,\n/storybook/a35ecd5cb43cbcc0843467f82e8839d7/c9669/logo-brand-leer%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/a35ecd5cb43cbcc0843467f82e8839d7/c45c5/logo-brand-leer%403x.webp 180w,\n/storybook/a35ecd5cb43cbcc0843467f82e8839d7/79124/logo-brand-leer%403x.webp 360w,\n/storybook/a35ecd5cb43cbcc0843467f82e8839d7/4e0d9/logo-brand-leer%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "c43a03e2-3714-544a-ac3d-c26947cc226c"
      }
    }
  },
  brand_logo_prime: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/6226c515c26b5d34c5b7e9611f6be9bc/c9669/logo-brand-prime-design%403x.png",
              srcSet:
                "/storybook/6226c515c26b5d34c5b7e9611f6be9bc/8e8c5/logo-brand-prime-design%403x.png 180w,\n/storybook/6226c515c26b5d34c5b7e9611f6be9bc/35ed9/logo-brand-prime-design%403x.png 360w,\n/storybook/6226c515c26b5d34c5b7e9611f6be9bc/c9669/logo-brand-prime-design%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/6226c515c26b5d34c5b7e9611f6be9bc/c45c5/logo-brand-prime-design%403x.webp 180w,\n/storybook/6226c515c26b5d34c5b7e9611f6be9bc/79124/logo-brand-prime-design%403x.webp 360w,\n/storybook/6226c515c26b5d34c5b7e9611f6be9bc/4e0d9/logo-brand-prime-design%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "48b09f8d-99ea-5e42-bf3f-84896d623786"
      }
    }
  },
  brand_logo_pulse: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 240px) 240px, 100vw",
              src: "/storybook/fbd3934bb1ad6d211dc89f159bbf0b60/05c64/logo-brand-pulse.png",
              srcSet:
                "/storybook/fbd3934bb1ad6d211dc89f159bbf0b60/c7f0f/logo-brand-pulse.png 60w,\n/storybook/fbd3934bb1ad6d211dc89f159bbf0b60/a3143/logo-brand-pulse.png 120w,\n/storybook/fbd3934bb1ad6d211dc89f159bbf0b60/05c64/logo-brand-pulse.png 240w"
            },
            sources: [
              {
                sizes: "(min-width: 240px) 240px, 100vw",
                srcSet:
                  "/storybook/fbd3934bb1ad6d211dc89f159bbf0b60/abb2f/logo-brand-pulse.webp 60w,\n/storybook/fbd3934bb1ad6d211dc89f159bbf0b60/66f11/logo-brand-pulse.webp 120w,\n/storybook/fbd3934bb1ad6d211dc89f159bbf0b60/ff8ad/logo-brand-pulse.webp 240w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "899c9f80-5672-5b02-a28c-470b38956aa1"
      }
    }
  },
  brand_logo_ranger: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/9a12d83229907a5fe22490d642ffadf2/c9669/logo-brand-ranger-design%403x.png",
              srcSet:
                "/storybook/9a12d83229907a5fe22490d642ffadf2/8e8c5/logo-brand-ranger-design%403x.png 180w,\n/storybook/9a12d83229907a5fe22490d642ffadf2/35ed9/logo-brand-ranger-design%403x.png 360w,\n/storybook/9a12d83229907a5fe22490d642ffadf2/c9669/logo-brand-ranger-design%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/9a12d83229907a5fe22490d642ffadf2/c45c5/logo-brand-ranger-design%403x.webp 180w,\n/storybook/9a12d83229907a5fe22490d642ffadf2/79124/logo-brand-ranger-design%403x.webp 360w,\n/storybook/9a12d83229907a5fe22490d642ffadf2/4e0d9/logo-brand-ranger-design%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "c4dbe8d8-ab48-5815-9b6b-afd1ca9a961c"
      }
    }
  },
  brand_logo_slick_locks: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/ea6fabde9df922f69586432e3dd676ba/c9669/logo-brand-slick-locks%403x.png",
              srcSet:
                "/storybook/ea6fabde9df922f69586432e3dd676ba/8e8c5/logo-brand-slick-locks%403x.png 180w,\n/storybook/ea6fabde9df922f69586432e3dd676ba/35ed9/logo-brand-slick-locks%403x.png 360w,\n/storybook/ea6fabde9df922f69586432e3dd676ba/c9669/logo-brand-slick-locks%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/ea6fabde9df922f69586432e3dd676ba/c45c5/logo-brand-slick-locks%403x.webp 180w,\n/storybook/ea6fabde9df922f69586432e3dd676ba/79124/logo-brand-slick-locks%403x.webp 360w,\n/storybook/ea6fabde9df922f69586432e3dd676ba/4e0d9/logo-brand-slick-locks%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "2ce06710-ee0e-55d6-bbac-3f18555bb0f6"
      }
    }
  },
  brand_logo_stahl: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 650,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/a114cce4304b0a3230da331823e723ae/c9669/logo-brand-stahl%403x.png",
              srcSet:
                "/storybook/a114cce4304b0a3230da331823e723ae/8e8c5/logo-brand-stahl%403x.png 180w,\n/storybook/a114cce4304b0a3230da331823e723ae/35ed9/logo-brand-stahl%403x.png 360w,\n/storybook/a114cce4304b0a3230da331823e723ae/c9669/logo-brand-stahl%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/a114cce4304b0a3230da331823e723ae/c45c5/logo-brand-stahl%403x.webp 180w,\n/storybook/a114cce4304b0a3230da331823e723ae/79124/logo-brand-stahl%403x.webp 360w,\n/storybook/a114cce4304b0a3230da331823e723ae/4e0d9/logo-brand-stahl%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "4a2faf96-c740-5cb5-b5e8-c6804158b015"
      }
    }
  },
  default: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#d8e8f8",
          height: 414.99999999999994,
          images: {
            fallback: {
              sizes: "(min-width: 1000px) 1000px, 100vw",
              src: "/storybook/9e01fe059bae0bedd117446c113abf11/b44b0/large-dark.jpg",
              srcSet:
                "/storybook/9e01fe059bae0bedd117446c113abf11/f782f/large-dark.jpg 250w,\n/storybook/9e01fe059bae0bedd117446c113abf11/24d85/large-dark.jpg 500w,\n/storybook/9e01fe059bae0bedd117446c113abf11/b44b0/large-dark.jpg 1000w,\n/storybook/9e01fe059bae0bedd117446c113abf11/8ba2d/large-dark.jpg 2000w"
            },
            sources: [
              {
                sizes: "(min-width: 1000px) 1000px, 100vw",
                srcSet:
                  "/storybook/9e01fe059bae0bedd117446c113abf11/2a4db/large-dark.webp 250w,\n/storybook/9e01fe059bae0bedd117446c113abf11/c0386/large-dark.webp 500w,\n/storybook/9e01fe059bae0bedd117446c113abf11/e8e37/large-dark.webp 1000w,\n/storybook/9e01fe059bae0bedd117446c113abf11/9dca3/large-dark.webp 2000w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1000
        }
      }
    }
  },
  detail_1: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#d8b8a8",
          height: 1200,
          images: {
            fallback: {
              sizes: "(min-width: 1200px) 1200px, 100vw",
              src: "/storybook/d75f8d07d07533915b36a3f2c4c3d28f/e8b76/detail-full-1%403x.png",
              srcSet:
                "/storybook/d75f8d07d07533915b36a3f2c4c3d28f/30cdc/detail-full-1%403x.png 300w,\n/storybook/d75f8d07d07533915b36a3f2c4c3d28f/c7240/detail-full-1%403x.png 600w,\n/storybook/d75f8d07d07533915b36a3f2c4c3d28f/e8b76/detail-full-1%403x.png 1200w"
            },
            sources: [
              {
                sizes: "(min-width: 1200px) 1200px, 100vw",
                srcSet:
                  "/storybook/d75f8d07d07533915b36a3f2c4c3d28f/078c3/detail-full-1%403x.webp 300w,\n/storybook/d75f8d07d07533915b36a3f2c4c3d28f/6d09e/detail-full-1%403x.webp 600w,\n/storybook/d75f8d07d07533915b36a3f2c4c3d28f/83805/detail-full-1%403x.webp 1200w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "f6a4698c-3177-5cb4-b2d6-26605378fa93"
      }
    }
  },
  detail_2: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#b8c8d8",
          height: 1200,
          images: {
            fallback: {
              sizes: "(min-width: 300px) 300px, 100vw",
              src: "/storybook/9923f5030cbdb37d0a882de434ec3b2f/30cdc/detail-thumbnail-2%403x.png",
              srcSet:
                "/storybook/9923f5030cbdb37d0a882de434ec3b2f/7458e/detail-thumbnail-2%403x.png 75w,\n/storybook/9923f5030cbdb37d0a882de434ec3b2f/de3a1/detail-thumbnail-2%403x.png 150w,\n/storybook/9923f5030cbdb37d0a882de434ec3b2f/30cdc/detail-thumbnail-2%403x.png 300w"
            },
            sources: [
              {
                sizes: "(min-width: 300px) 300px, 100vw",
                srcSet:
                  "/storybook/9923f5030cbdb37d0a882de434ec3b2f/18188/detail-thumbnail-2%403x.webp 75w,\n/storybook/9923f5030cbdb37d0a882de434ec3b2f/c65bc/detail-thumbnail-2%403x.webp 150w,\n/storybook/9923f5030cbdb37d0a882de434ec3b2f/078c3/detail-thumbnail-2%403x.webp 300w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "207b8f00-8f56-5408-8395-fe0588ef4d71"
      }
    }
  },
  detail_3: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#f8f8f8",
          height: 1200,
          images: {
            fallback: {
              sizes: "(min-width: 300px) 300px, 100vw",
              src: "/storybook/1a6bdcd433453a7a859408f9de0fe7df/30cdc/detail-thumbnail-3%403x.png",
              srcSet:
                "/storybook/1a6bdcd433453a7a859408f9de0fe7df/7458e/detail-thumbnail-3%403x.png 75w,\n/storybook/1a6bdcd433453a7a859408f9de0fe7df/de3a1/detail-thumbnail-3%403x.png 150w,\n/storybook/1a6bdcd433453a7a859408f9de0fe7df/30cdc/detail-thumbnail-3%403x.png 300w"
            },
            sources: [
              {
                sizes: "(min-width: 300px) 300px, 100vw",
                srcSet:
                  "/storybook/1a6bdcd433453a7a859408f9de0fe7df/18188/detail-thumbnail-3%403x.webp 75w,\n/storybook/1a6bdcd433453a7a859408f9de0fe7df/c65bc/detail-thumbnail-3%403x.webp 150w,\n/storybook/1a6bdcd433453a7a859408f9de0fe7df/078c3/detail-thumbnail-3%403x.webp 300w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "890b5a1e-b147-59dd-9638-ac6cc257ba16"
      }
    }
  },
  detail_4: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#e8e8e8",
          height: 1200,
          images: {
            fallback: {
              sizes: "(min-width: 300px) 300px, 100vw",
              src: "/storybook/3e7904ba393cb94729b03a2a3a0c76e7/30cdc/detail-thumbnail-4%403x.png",
              srcSet:
                "/storybook/3e7904ba393cb94729b03a2a3a0c76e7/7458e/detail-thumbnail-4%403x.png 75w,\n/storybook/3e7904ba393cb94729b03a2a3a0c76e7/de3a1/detail-thumbnail-4%403x.png 150w,\n/storybook/3e7904ba393cb94729b03a2a3a0c76e7/30cdc/detail-thumbnail-4%403x.png 300w"
            },
            sources: [
              {
                sizes: "(min-width: 300px) 300px, 100vw",
                srcSet:
                  "/storybook/3e7904ba393cb94729b03a2a3a0c76e7/18188/detail-thumbnail-4%403x.webp 75w,\n/storybook/3e7904ba393cb94729b03a2a3a0c76e7/c65bc/detail-thumbnail-4%403x.webp 150w,\n/storybook/3e7904ba393cb94729b03a2a3a0c76e7/078c3/detail-thumbnail-4%403x.webp 300w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "4b07b9da-3449-5bea-882b-3022cac94733"
      }
    }
  },
  header_hero: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#a8b8d8",
          height: 351,
          images: {
            fallback: {
              sizes: "(min-width: 1200px) 1200px, 100vw",
              src: "/storybook/ffb10675241610a0d51ca5544dc11d08/f327e/home-header%403x.png",
              srcSet:
                "/storybook/ffb10675241610a0d51ca5544dc11d08/002ee/home-header%403x.png 300w,\n/storybook/ffb10675241610a0d51ca5544dc11d08/14ab0/home-header%403x.png 600w,\n/storybook/ffb10675241610a0d51ca5544dc11d08/f327e/home-header%403x.png 1200w,\n/storybook/ffb10675241610a0d51ca5544dc11d08/c0c9c/home-header%403x.png 2400w"
            },
            sources: [
              {
                sizes: "(min-width: 1200px) 1200px, 100vw",
                srcSet:
                  "/storybook/ffb10675241610a0d51ca5544dc11d08/3f330/home-header%403x.webp 300w,\n/storybook/ffb10675241610a0d51ca5544dc11d08/98bc5/home-header%403x.webp 600w,\n/storybook/ffb10675241610a0d51ca5544dc11d08/df6f2/home-header%403x.webp 1200w,\n/storybook/ffb10675241610a0d51ca5544dc11d08/40f42/home-header%403x.webp 2400w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "36ddd4e9-1f2f-50b6-ac3e-736022879b92"
      }
    }
  },
  image_1: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#d8c8c8",
          height: 800,
          images: {
            fallback: {
              sizes: "(min-width: 720px) 720px, 100vw",
              src: "/storybook/3d52bba2e7d80674a1fc1e48239e2ae0/e9975/card-thumbnail%403x.png",
              srcSet:
                "/storybook/3d52bba2e7d80674a1fc1e48239e2ae0/cb5d0/card-thumbnail%403x.png 180w,\n/storybook/3d52bba2e7d80674a1fc1e48239e2ae0/affa5/card-thumbnail%403x.png 360w,\n/storybook/3d52bba2e7d80674a1fc1e48239e2ae0/e9975/card-thumbnail%403x.png 720w"
            },
            sources: [
              {
                sizes: "(min-width: 720px) 720px, 100vw",
                srcSet:
                  "/storybook/3d52bba2e7d80674a1fc1e48239e2ae0/ff3fb/card-thumbnail%403x.webp 180w,\n/storybook/3d52bba2e7d80674a1fc1e48239e2ae0/22e2a/card-thumbnail%403x.webp 360w,\n/storybook/3d52bba2e7d80674a1fc1e48239e2ae0/4da88/card-thumbnail%403x.webp 720w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "e284c0ac-3203-51d3-9f41-148fb0c667eb"
      }
    }
  },
  image_2: {
    src: {
      childImageSharp: {
        gatsbyImageData: {
          backgroundColor: "#5878b8",
          height: 729,
          images: {
            fallback: {
              sizes: "(min-width: 1200px) 1200px, 100vw",
              src: "/storybook/bd5f7b05a74215f0c4453eb0dca67b1e/6a617/about-fleet%403x.png",
              srcSet:
                "/storybook/bd5f7b05a74215f0c4453eb0dca67b1e/fa1bc/about-fleet%403x.png 300w,\n/storybook/bd5f7b05a74215f0c4453eb0dca67b1e/bb72b/about-fleet%403x.png 600w,\n/storybook/bd5f7b05a74215f0c4453eb0dca67b1e/6a617/about-fleet%403x.png 1200w"
            },
            sources: [
              {
                sizes: "(min-width: 1200px) 1200px, 100vw",
                srcSet:
                  "/storybook/bd5f7b05a74215f0c4453eb0dca67b1e/861a1/about-fleet%403x.webp 300w,\n/storybook/bd5f7b05a74215f0c4453eb0dca67b1e/25b28/about-fleet%403x.webp 600w,\n/storybook/bd5f7b05a74215f0c4453eb0dca67b1e/8ccf0/about-fleet%403x.webp 1200w",
                type: "image/webp"
              }
            ]
          },
          layout: "constrained",
          width: 1200
        },
        id: "329b2294-a4d7-576f-b4ec-ac069edf289d"
      }
    }
  },
  native: {
    height: "718",
    src: "/storybook/9e01fe059bae0bedd117446c113abf11/b44b0/large-dark.jpg",
    width: "1077"
  }
}
