import imageFixture from "../image/fixtures"

export default {
  default: {
    heading: "Truck Equipment",
    image: imageFixture.default.src,
    url: "/"
  },
  media: {
    body: "<p>Truck Equipment</p>",
    heading: "Caps & Toppers",
    image: imageFixture.default.src,
    theme: "media",
    url: "/"
  },
  with_background_image: {
    body: "<p>Whether you need truck caps or truck covers, we cover a wide range of accessories from brands you know and trust.</p>",
    button_label: "See all truck upgrades",
    heading: "Truck Equipment",
    image: imageFixture.default.src,
    theme: "background-image",
    url: "/"
  }
}
