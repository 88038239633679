export default {
  buttons: [
    {
      label: "Contact Us",
      link: "/contact",
      theme: "Arrow Without Padding"
    }
  ],
  collection: [
    {
      links: [
        { href: "/", title: "Truck Storage" },
        { href: "/", title: "Cargo Sliders" },
        { href: "/", title: "Service Bodies" },
        { href: "/", title: "Ladder Racks" },
        { href: "/", title: "Tonneau Covers" },
        { href: "/", title: "Truck Caps" }
      ],
      title: "Truck Equipment"
    },
    {
      links: [
        { href: "/", title: "Van Packages" },
        { href: "/", title: "Partitions & Bulkheads" },
        { href: "/", title: "Shelving" },
        { href: "/", title: "Storage Units" },
        { href: "/", title: "Floor Storage" },
        { href: "/", title: "Racks & Holders" },
        { href: "/", title: "Cargo Sliders" },
        { href: "/", title: "Roof Storage" },
        { href: "/", title: "Flooring" }
      ],
      title: "Van Equipment"
    },
    {
      links: [
        { href: "/", title: "Vehicle Decals & Wraps" },
        { href: "/", title: "Vehicle Security" },
        { href: "/", title: "Lighting" }
      ],
      title: "Accessories"
    },
    {
      links: [
        { href: "/", title: "Specials" },
        { href: "/", title: "Resource Center" },
        { href: "/", title: "About" },
        { href: "/", title: "Contact" }
      ],
      title: "More"
    }
  ],
  facebookUrl: "https://www.facebook.com/mikealbertfleetsolutions/",
  instagramUrl: "https://www.instagram.com/mikealbertmobility/",
  leftContent:
    "<h2>Visit the showroom</h2><p>We accept walk-ins from 8-5:30 Monday through Friday, with availability by appointment on Saturdays. Set up a time by calling <a href='tel:513-554-2800'>513-554-2800</a>.</p>",
  linkedinUrl: "https://www.linkedin.com/company/mike-albert-fleet-solutions/",
  rightContent:
    "## Mike Albert Truck & Van Equipment\n10340 Evendale Drive\nCincinnati, OH 45241\n[513-554-2800](tel:5135542800)",
  twitterUrl: "https://twitter.com/MAmobility",
  youtubeUrl: "https://www.youtube.com/channel/UCmTQ847QZixnbCF8VGeH3Ng/featured"
}
