export default {
  links: [
    {
      children: [{ href: "/", title: "Truck Storage Link 1" }],
      title: "Truck Equipment"
    },
    {
      children: [{ href: "/", title: "Van Storage Link 1" }],
      title: "Van Equipment"
    },
    {
      children: [
        { href: "/", title: "Accessories Link 1" },
        { href: "/", title: "Accessories Link 2" }
      ],
      title: "Accessories"
    },
    {
      href: "/",
      title: "Specials"
    },
    {
      href: "/",
      title: "About"
    }
  ]
}
