import React from "react"
import PropTypes from "prop-types"

import SocialNav from "./social-nav"
import SVG from "@components/svg"
import Link from "@components/link"
import Button from "@components/button"
import Container from "@layout/container"

import * as styles from "./styles.module.scss"

// ---------------------------------------------------------
const Footer = ({
  buttons,
  leftContent,
  leftContentNode,
  rightContent,
  rightContentNode,
  collection = [],
  linkedinUrl,
  twitterUrl,
  youtubeUrl,
  facebookUrl,
  instagramUrl
}) => {
  const leftBodyContent =
    leftContentNode && leftContentNode.childMarkdownRemark
      ? leftContentNode.childMarkdownRemark.html
      : leftContent

  const rightBodyContent =
    rightContentNode && rightContentNode.childMarkdownRemark
      ? rightContentNode.childMarkdownRemark.html
      : rightContent

  return (
    <footer className={styles.footer}>
      <Container
        padding="footer"
        notch="notch-is-top-left-half"
        theme="background-is-black"
        hasChildren
      >
        <div className={styles.footer_content}>
          <div className={styles.footer_content_blocks}>
            <div
              dangerouslySetInnerHTML={{
                __html: leftBodyContent
              }}
            ></div>
            {buttons &&
              buttons.map((button, index) => {
                return (
                  <Button
                    key={index}
                    className={styles.button}
                    theme={button.theme.toLowerCase().replace(/\s/g, "-")}
                    to={button.link}
                  >
                    {button.label}
                  </Button>
                )
              })}
            <SocialNav
              links={[
                { icon: "facebook", url: facebookUrl },
                { icon: "linkedin", url: linkedinUrl },
                { icon: "twitter", url: twitterUrl },
                { icon: "youtube", url: youtubeUrl },
                { icon: "instagram", url: instagramUrl }
              ]}
            />
          </div>

          <div>
            <div className={styles.footer_info}>
              <SVG name="logo-mark" />
              <div
                dangerouslySetInnerHTML={{
                  __html: rightBodyContent
                }}
              ></div>
            </div>

            <nav className={styles.footer_nav}>
              {collection.map((menu, i) => (
                <div key={i}>
                  <h3>{menu.title}</h3>
                  <ul>
                    {menu.links.map((link, j) => (
                      <li key={j}>
                        <Button theme="arrow-without-padding" to={link.href}>
                          {link.title}
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </nav>
          </div>
        </div>
      </Container>

      <Container
        contentClassName={styles.footer_banner}
        padding="none"
        notch="notch-is-top-left-footer"
        theme="background-is-green"
        hasChildren
      >
        <h6>Other Mike Albert Businesses</h6>

        <Link to="https://www.mikealbertrental.com">
          <SVG name="logo-rental" />
        </Link>

        <Link to="https://www.mikealbertcars.com">
          <SVG name="logo-sales-and-service" />
        </Link>

        <Link to="https://www.mikealbert.com/">
          <SVG name="logo-fleet-solutions" />
        </Link>
      </Container>
    </footer>
  )
}

Footer.propTypes = {
  buttons: PropTypes.array,
  collection: PropTypes.array,
  facebookUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  leftContent: PropTypes.string,
  leftContentNode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  linkedinUrl: PropTypes.string,
  rightContent: PropTypes.string,
  rightContentNode: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  socialNav: PropTypes.object,
  twitterUrl: PropTypes.string,
  youtubeUrl: PropTypes.string
}

export default Footer
